<template>
  <!--  <v-container ma-0 fluid>-->
  <!--    <v-layout fill-height>-->
  <v-container column justify-start align="top" fluid>
    <v-row>
      <v-col>
        <v-text-field
            v-model="search.text"
            append-icon="mdi-magnify"
            label="Search (name or full id number)"
            single-line
            hide-details
        />
        <!--          <v-checkbox v-model="search.onlyCategories" label="Toggle Catgeories"></v-checkbox>-->
      </v-col>
      <v-spacer/>
      <v-checkbox
          v-model="showComponents"
          label="Show Details"
          single-line
          hide-details
      />
      <v-spacer/>
      <v-checkbox
          v-model="onlyVerified"
          label="Only Verified"
          single-line
          hide-details
      />
      <v-spacer/>
      <!--      <div class="ma-6 caption">-->
      <!--          click a component to view or edit details-->
      <!--        </div>-->

    </v-row>
    <v-data-table
        :headers="showComponents ? [] : headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalCount"
        :loading="loading"
        :footer-props="footerProps"
        :expanded.sync="expanded"
        class="mb-15"
        dense
        @click:row="redirectToComponentHandler"
    >
      <template v-if="showComponents" v-slot:item="{ item }">
        <v-lazy>
          <ComponentDetail
              :component-id="item.id"

          />
        </v-lazy>
      </template>
      <template v-slot:item.inserted_at="{ item }">
        {{ formatDate(item.inserted_at) }}

      </template>
      <!--          <template v-slot:item.fooddateofentry="{ item }">-->
      <!--            <span v-if="item.fooddateofentry">-->
      <!--              {{ formatDate(item.fooddateofentry) }}-->
      <!--            </span>-->
      <!--          </template>-->

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0 ma-0">
          <ComponentDetail
              :component-id="item.id"
          />
        </td>
      </template>
    </v-data-table>

    <!--        <v-data-table-->
    <!--            :search="search"-->
    <!--            :headers="headers"-->
    <!--            :items="items"-->
    <!--            :options.sync="options"-->
    <!--            :server-items-length="totalCount"-->
    <!--            :loading="loading"-->
    <!--            class="mb-15"-->
    <!--            show-group-by-->
    <!--        />-->
  </v-container>
  <!--    </v-layout>-->
  <!--  </v-container>-->
</template>

<script>
import api from '@/api';
import moment from 'moment';
import {mapActions} from 'vuex';
import ComponentDetail from '@/components/ComponentDetail';


export default {
  components: {ComponentDetail},
  data() {
    return {
      totalCount: 0,
      items: [],
      loading: true,
      options: {},
      headers: [
        {text: 'component id', value: 'id', width: '80px'},
        // {text: 'food id', value: 'foodid', width: '80px'},
        {text: 'component name', value: 'name'},
        {text: 'category', value: 'category'},
        {text: 'verified', value: 'verified'},
        {text: 'component created', value: 'inserted_at'},
        {text: 'description', value: 'description'},
        {text: 'monosized', value: 'monosized', width: '80px'},
        {text: 'small_ratio', value: 'small_ratio', width: '80px'},
        {text: 'large_ratio', value: 'large_ratio', width: '80px'},
        // {text: 'Change %', value: 'shoppingmultiplier', width: '80px'},
        // {text: 'fooddateofentry', value: 'fooddateofentry', width: '80px'},
      ],
      search: {
        text: '',
        onlyComponents: false,
        // onlyCategories: false,
      },
      footerProps: {
        itemsPerPageOptions: [10, 20, -1]
      },
      skipNextLoad: false,
      expanded: [],
      showComponents: false,
      onlyVerified: false
    }
  },
  watch: {
    onlyVerified: 'getDataFromApi',
    search: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },
    expanded() {
      // console.log('this.expanded',JSON.stringify(this.expanded));
      const idsToShow = (this.expanded.map(i => i.id));
      if (this.$route.query.show != idsToShow[0]) {
        console.log('updating query', {'this.$route.query': this.$route.query.show, idsToShow});
        this.$router.push({query: {show: idsToShow}});
      }
      console.log('expanded', this.expanded);
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi()
        .then(() => {
          const show = this.$route.query.show;
          if (show) {
            //ensure that expanded is set to what the route says
            const id = show;
            const item = this.items.find(i => i.id == id);
            if (item) {
              this.expanded = [item]
            } else {
              console.warn('could not find item to expand', {show});
            }
          }
        });
  },
  methods: {
    ...mapActions([
      'fetchComponent'
    ]),
    buildSearchQuery(search) {
      let where= {};

      if (this.onlyVerified) {
        where.verified = true;
      }
      if (!search) {
        return where;
      } else {
        where = {
          ...where,
          OR: [
            {
              name: {
                contains: search,
                mode: 'insensitive',
              },
            },
            {
              description: {
                contains: search,
                mode: 'insensitive',
              },
            },
            {
              category: {
                contains: search,
                mode: 'insensitive',
              },
            },
          ],
        }

        const searchInt = parseInt(search);
        if (!isNaN(searchInt) && `${searchInt}` === search)
          where.OR.push(...[
                {
                  id: {
                    equals: searchInt,
                  }
                }
              ]
          );

        return where;
      }
    },
    getDataFromApi() {
      if (this.skipNextLoad) {
        console.log('skip load');
        this.skipNextLoad = false;
        return;
      }

      const {sortBy, sortDesc, page = 1, itemsPerPage = 10} = this.options
      console.log('options', {sortBy, sortDesc, page, itemsPerPage});
      this.loading = true;
      const from = (page - 1) * itemsPerPage;
      const limit = itemsPerPage;
      let where = this.buildSearchQuery(this.search.text);
      // if (where) {
      //   where = {
      //     AND: [
      //       this.search.onlyComponents ? notNullComponent : nullComponent,
      //       where
      //     ]
      //   }
      // } else {
      //   where = this.search.onlyComponents ? notNullComponent : nullComponent;
      // }

      return api.get(`v2/component`, {
        params: {
          from,
          limit,
          sortBy,
          sortDesc,
          where
        }
      })
          .then(response => {
            console.log('response', response)
            const {data: {rows, total}} = response;
            console.log('got data', {rows, total});
            this.$set(this, 'items', rows);
            this.totalCount = total;
            if (itemsPerPage > -1) {
              const lastPage = Math.floor(total / itemsPerPage) + 1;
              if (page > lastPage) {
                console.log('setting last page');
                this.options.page = lastPage;
                this.skipNextLoad = true;
              }
            }
          })
          .finally(() => {
            this.loading = false;
          })
    },
    formatDate(date) {
      return moment(date).format(moment.HTML5_FMT.DATE)
    },
    redirectToComponentHandler(item) {
      // console.log('redirect',{page: 'ComponentDetail', params : { id : item.id }});
      this.$router.push({name: 'ComponentDetail', params: {id: item.id}});
    }
  }
}
</script>

<style>
/*.v-data-table-header {*/
/*  position: fixed;*/
/*  width: 100vw;*/
/*  background: inherit;*/
/*}*/

.v-data-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: inherit;
}
</style>
