import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"column":"","justify-start":"","align":"top","fluid":""}},[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search (name or full id number)","single-line":"","hide-details":""},model:{value:(_vm.search.text),callback:function ($$v) {_vm.$set(_vm.search, "text", $$v)},expression:"search.text"}})],1),_c(VSpacer),_c(VCheckbox,{attrs:{"label":"Show Details","single-line":"","hide-details":""},model:{value:(_vm.showComponents),callback:function ($$v) {_vm.showComponents=$$v},expression:"showComponents"}}),_c(VSpacer),_c(VCheckbox,{attrs:{"label":"Only Verified","single-line":"","hide-details":""},model:{value:(_vm.onlyVerified),callback:function ($$v) {_vm.onlyVerified=$$v},expression:"onlyVerified"}}),_c(VSpacer)],1),_c(VDataTable,{staticClass:"mb-15",attrs:{"headers":_vm.showComponents ? [] : _vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading,"footer-props":_vm.footerProps,"expanded":_vm.expanded,"dense":""},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.redirectToComponentHandler},scopedSlots:_vm._u([(_vm.showComponents)?{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VLazy,[_c('ComponentDetail',{attrs:{"component-id":item.id}})],1)]}}:null,{key:"item.inserted_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.inserted_at))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0 ma-0",attrs:{"colspan":headers.length}},[_c('ComponentDetail',{attrs:{"component-id":item.id}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }